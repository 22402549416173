export function getConnected(state) {
    return state.musicName
}

export function getMusicMap(state) {
    return state.songMap
}

export function getPlaySong(state) {
    return state.playSong
}

<template>
  <div>
    <span>ABOUT</span>
  </div>
</template>

<script setup>

</script>

<style>

</style>
<template>
  <div class="text-wrapper">
    <div class="text">settings</div>
  </div>
</template>

<script setup>

</script>

<style></style>
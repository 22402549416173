export function SET_MUSIC(state, payload) {
    state.musicName = payload
}

export function SET_MUSIC_MAP(state, payload) {
    state.songMap = payload
}

export function SET_PLAY_SONG(state, payload) {
    state.playSong = payload
}